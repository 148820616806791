import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { externalTooltip } from "../../../../common/chartHelper";
import { useState } from "react";
import commaNumber from "comma-number";
import { useEffect } from "react";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const isMobile = () => window.innerWidth < 749;

const StackBarChart = ({ labels, revenue, ccost, blurb1, blurb2 }) => {
  const chartRef = useRef();
  const [isHovered, setIsHovered] = useState(false);

  // Tells if the Saving Value is a valid number or not
  const [isValidValue, setIsValidValue] = useState(false);
  const [formattedSavingValue, setFormattedSavingValue] = useState("-");

  const { v2Landing, fullCalc } = useSelector((s) => s.ui);

  const options = {
    layout: {
      padding: {
        top: fullCalc ? 20 : isMobile() ? (v2Landing ? 20 : 0) : 20,
        bottom: 5,
        left: fullCalc
          ? isMobile()
            ? 0
            : 120
          : isMobile()
          ? v2Landing
            ? 0
            : 50
          : 120,
        right: fullCalc
          ? isMobile()
            ? 0
            : 30
          : isMobile()
          ? v2Landing
            ? 0
            : 10
          : 30,
      },
    },
    indexAxis: "y",
    yAlign: "top",
    plugins: {
      tooltip: {
        enabled: false,
        external: (c) => externalTooltip(c, v2Landing),
        yAlign: fullCalc ? "top" : "bottom",
        callbacks: {
          label: function (context) {
            if (context?.raw > 0) {
              return `${context?.dataset?.label}: ${parseInt(
                context?.raw?.toFixed()
              )?.toLocaleString()}`;
            } else if (context.raw < 0) {
              return `${context?.dataset?.label}: (${parseInt(
                (context?.raw * -1)?.toFixed()
              )?.toLocaleString()})`;
            }
          },
        },
      },
      legend: {
        display: false,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "y",
    },
    animation: {
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default") {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },

    scales: {
      x: {
        ticks: {
          callback: (value) => {
            if (Math.abs(value) < 1000 || !fullCalc) {
              return value < 0
                ? `(${(value * -1)?.toLocaleString()})`
                : `${value?.toLocaleString()}`;
            } else if (Math.abs(value) < 1_000_000) {
              return value < 0
                ? `(${Math.abs(Math.floor(value / 1000))}k)`
                : `${Math.abs(Math.floor(value / 1000))}k`;
            } else {
              return value < 0
                ? `(${Math.abs((value / 1_000_000).toFixed(1))}M)`
                : `${Math.abs((value / 1_000_000).toFixed(1))}M`;
            }
          },
          maxRotation: 0,
          minRotation: 0,
          color: "#22577A",
          font: {
            weight: 600,
            size: 12,
          },
        },
        stacked: true,
        grid: {
          display: true,
          tickLength: 10,
          borderWidth: 0,
          borderColor: "#38A3A524",
          drawBorder: true,
          drawOnChartArea: false,
          tickColor: "#38A3A524",
        },
      },
      y: {
        ticks: {
          color: "#22577A",
          font: {
            align: "end",
            weight: 400,
            size: 10,
          },
        },
        stacked: true,
        display: !isMobile() || (!fullCalc && !v2Landing),
        position: "right",
        grid: {
          display: false,
          borderWidth: 0,
        },
      },
    },
  };

  const data = (labels, revenue, cCost) => {
    return {
      labels,
      datasets: [
        {
          label: "Revenue",
          data: revenue,
          backgroundColor: isHovered ? "#80ED9940" : "#80ED99",
          hoverBackgroundColor: "#80ED99",
          borderColor: "#80ED99",
          borderWidth: 0,
          maxBarThickness: () =>
            isMobile()
              ? v2Landing
                ? 25
                : 30
              : fullCalc
              ? 45
              : v2Landing
              ? 40
              : 35,
          borderRadius: 6,
          datalabels: {
            display: false,
          },
        },
        {
          label: "Other Marketing Costs",
          data: cCost,
          backgroundColor: isHovered ? "#38A3A540" : "#38A3A5",
          hoverBackgroundColor: "#38A3A5",
          borderColor: "#38A3A5",
          borderWidth: 0,
          barPercentage: 0.7,
          maxBarThickness: () =>
            isMobile()
              ? v2Landing
                ? 25
                : 30
              : fullCalc
              ? 45
              : v2Landing
              ? 40
              : 35,
          borderRadius: 6,
          datalabels: {
            display: false,
          },
        },
      ],
    };
  };

  const toggleData = (value) => {
    const chart = chartRef.current;
    chart.isDatasetVisible(value) ? chart.hide(value) : chart.show(value);
  };

  const onChartHoverChange = (hovered) => {
    if (hovered !== isHovered) {
      setIsHovered(hovered);
    }
  };

  const redirectToApp = () => {
    window.open("https://www.revenueroll.com/book-demo");
  };

  useEffect(() => {
    if (isNaN(blurb1)) {
      setIsValidValue(false);
      setFormattedSavingValue("-");
    } else if (blurb1 < 0) {
      setIsValidValue(false);
      setFormattedSavingValue("$$");
    } else {
      setIsValidValue(true);
      setFormattedSavingValue(commaNumber(Number(blurb1).toFixed(0)));
    }
  }, [blurb1]);

  return (
    <div className="relative stack-bar-chart">
      <div className="flex items-center chart-heading-wrapper">
        <p className="sub-heading">Revenue Roll Minimizes Costs</p>
        <div className="group relative">
          <div className="info-icon">
            <p>!</p>
          </div>

          <div className="hidden group-hover:block">
            <div className="section-info-div">
              <ul>
                <li>
                  Align your contractors with your success - only pay for what
                  you get
                </li>
                <li>
                  You pay your contractors to increase traffic and revenue , so
                  why pay them when they don't perform?
                </li>
                <li>
                  Revenue Roll only gets paid if we deliver, we wouldn't want it
                  anyway else
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {!v2Landing && (
        <div className="box-btn-wrapper stack-bar-chart">
          <div className="number-box">
            <div className="box-content">
              <p>We Can Save You </p>
              <p className="box-value">${formattedSavingValue}</p>
              <p>{isValidValue ? "Per Year " : ""} Right Now</p>
            </div>
          </div>
          <button className="box-btn" onClick={redirectToApp}>
            Save Now
          </button>
        </div>
      )}

      <div className="hor-bar-chart-wrapper">
        <div className="hide-caption"></div>
        <div className="hor-bar-chart-overlays">
          <div className="status-quo-overlay">
            <p>Status Quo</p>
          </div>
          <div className="rr-man-overlay">
            <p>Revenue Roll</p>
            <p className="mobile-caption same-roas">Same ROAS</p>
            <p className="mobile-caption imp-roas">Improved ROAS</p>
          </div>
        </div>
        <Bar
          onMouseEnter={() => onChartHoverChange(true)}
          onMouseLeave={() => onChartHoverChange(false)}
          ref={chartRef}
          options={options}
          data={data(labels, revenue, ccost)}
        />
        <div className="chart-bottom-line"></div>

        <div className="chart-info-icons">
          <div className={"group relative" + (blurb1 <= 0 ? " invisible" : "")}>
            <div className="info-icon">
              <p>!</p>
            </div>

            <div className="hidden group-hover:block">
              <div className="section-info-div">
                <p>
                  If we perform exactly the same as your current provider, your
                  costs decrease (and profits increase) by $
                  {blurb1?.toLocaleString()} per year.
                </p>
              </div>
            </div>
          </div>

          <div className={"group relative" + (blurb2 <= 0 ? " invisible" : "")}>
            <div className="info-icon">
              <p>!</p>
            </div>

            <div className="hidden group-hover:block">
              <div className="section-info-div">
                <p>
                  Our customers have benefited from an average ROAS increase of
                  100% when switching to Revenue Roll. For you, this would
                  increase your profits by ${blurb2?.toLocaleString()} per year
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-legends lg:pl-[50px] pt-[10px]">
        {data().datasets.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <div className="legend" onClick={() => toggleData(i)}>
                <div
                  className="color-box"
                  style={{
                    background: d.hoverBackgroundColor,
                  }}
                ></div>{" "}
                <p>{d.label}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default StackBarChart;
