import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    v2Landing: false,
    fullCalc: true,
    parentPage: ""
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setV2Landing: (state, action) => {
            return { ...state, v2Landing: action.payload };
        },
        setFullCalc: (state, action) => {
            return { ...state, fullCalc: action.payload }
        },
        setParentPage: (state, action) => {
            return {...state, parentPage: action.payload}
        }
    },
});
export const { setV2Landing, setFullCalc, setParentPage } = uiSlice.actions;
export default uiSlice.reducer;
