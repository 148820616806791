import React from "react";
import NumberFormat from "react-number-format";

const RevenueEarnedInputMonthly = ({
  setTemp,
  setChangeFromAnnual,
  howMuchRevenueEarned,
  i_revenue_earned_monthly,
  handleOnlineRevenueDataUpdate,
}) => {
  return (
    <div
      className={`col-span-4 md:col-span-2  input-field-disabled`}
    >
      <NumberFormat
        thousandsGroupStyle="thousand"
        prefix={"$ "}
        // suffix={" %"}
        decimalSeparator="."
        displayType='text'
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        onValueChange={(e) => {
          setTemp(e.floatValue);
          setChangeFromAnnual(false);
          howMuchRevenueEarned(e, "monthly");
        }}
        value={i_revenue_earned_monthly}
        decimalScale={2}
        onBlur={() => {
          // if (temp !== undefined) {
          handleOnlineRevenueDataUpdate("revenue_earned");
          // }
        }}
      />
    </div>
  );
};

export default RevenueEarnedInputMonthly;
