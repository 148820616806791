import React, { useEffect, useState } from "react";
import InputSectionHeader from "../../InputSectionHeader";
import { useSelector, useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import RevenueInputMonthly from "../../../InputSections/OnlineRevenueInput/RevenueInputMonthly";
import RevenueInputAnnual from "../../../InputSections/OnlineRevenueInput/RevenueInputAnnual";
import OnlineChannelInputMonthly from "../../../InputSections/OnlineRevenueInput/OnlineChannelInputMonthly";
import OnlineChannelInputAnnual from "../../../InputSections/OnlineRevenueInput/OnlineChannelInputAnnual";
import EventWorthInputMonthly from "../../../InputSections/OnlineRevenueInput/EventWorthInputMonthly";
import RevenueEarnedInputMonthly from "../../../InputSections/OnlineRevenueInput/RevenueEarnedInputMonthly";
import RevenueEarnedInputAnnual from "../../../InputSections/OnlineRevenueInput/RevenueEarnedInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";

const OnlineRevenueSection = ({ className, param }) => {
  const [temp, setTemp] = useState(0);
  const data = useSelector((state) => state.data);

  // Input field states
  const [c_online_revenue_monthly, setC_online_revenue_monthly] = useState(20000);
  const [d_online_revenue_annual, setD_online_revenue_annual] = useState(c_online_revenue_monthly * 12);

  const [changeFromMonthly, setChangeFromMonthly] = useState(false);
  const [changeFromAnnual, setChangeFromAnnual] = useState(false);

  const [e_online_event_monthly, setE_online_event_monthly] = useState(1000);
  const [f_online_event_annual, setF_online_event_annual] = useState(e_online_event_monthly * 12);
  const [g_event_worth_monthly, set_g_event_worth_monthly] = useState(20);
  const [h_event_worth_annual, set_h_event_worth_annual] = useState(g_event_worth_monthly * 12);
  const [i_revenue_earned_monthly, set_i_revenue_earned_monthly] = useState(
    e_online_event_monthly * g_event_worth_monthly
  );
  const [j_revenue_earned_annual, set_j_revenue_earned_annual] = useState(i_revenue_earned_monthly * 12);

  const dispatch = useDispatch();

  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorTwo: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorTwo: false }));
    }
  }, [temp, dispatch]);

  useEffect(() => {
    setC_online_revenue_monthly(data.c_online_revenue_monthly);
    setE_online_event_monthly(data.e_online_event_monthly);
    set_g_event_worth_monthly(data.g_event_worth_monthly);
  }, [data.c_online_revenue_monthly, data.e_online_event_monthly, data.g_event_worth_monthly]);

  const handleOnlineRevenueDataUpdate = (fieldName) => {
    switch (fieldName) {
      case "online_revenue":
        if (
          data?.c_online_revenue_monthly !== c_online_revenue_monthly &&
          data?.d_online_revenue_annual !== d_online_revenue_annual
        ) {
          dispatch(addInputData({ c_online_revenue_monthly, d_online_revenue_annual }));
        }
        break;
      case "online_event":
        if (data?.e_online_event_monthly !== e_online_event_monthly)
          dispatch(
            addInputData({
              e_online_event_monthly,
              f_online_event_annual,
              i_revenue_earned_monthly,
              j_revenue_earned_annual,
            })
          );
        break;
      case "event_worth":
        if (data?.g_event_worth_monthly !== g_event_worth_monthly)
          dispatch(
            addInputData({
              g_event_worth_monthly,
              h_event_worth_annual,
              i_revenue_earned_monthly,
              j_revenue_earned_annual,
            })
          );
        break;
      case "revenue_earned" || "online_event":
        if (data?.i_revenue_earned_monthly !== i_revenue_earned_monthly)
          dispatch(addInputData({ i_revenue_earned_monthly, j_revenue_earned_annual }));
        break;
      default:
        return;
    }
  };

  const handleOnlineRevenueMonthly = (value) => {
    setC_online_revenue_monthly(value);
    setD_online_revenue_annual(value * 12);
    setChangeFromMonthly(true);
  };

  const handleOnlineRevenueAnnual = (value) => {
    setD_online_revenue_annual(value);
    setC_online_revenue_monthly(value / 12);
    setChangeFromAnnual(true);
  };

  const handleHowManyEvents = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      setE_online_event_monthly(e.floatValue);
      setF_online_event_annual(e.floatValue * 12);
      set_i_revenue_earned_monthly(e.floatValue * g_event_worth_monthly);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      setF_online_event_annual(e.floatValue);
      setE_online_event_monthly(e.floatValue / 12);
      set_i_revenue_earned_monthly((e.floatValue / 12) * g_event_worth_monthly);
      set_j_revenue_earned_annual(e.floatValue * g_event_worth_monthly);
      setChangeFromAnnual(true);
    }
  };

  const handleHowMuchEventGrowth = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_g_event_worth_monthly(e.floatValue);
      set_h_event_worth_annual(e.floatValue * 12);
      set_i_revenue_earned_monthly(e.floatValue * e_online_event_monthly);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_g_event_worth_monthly(e.floatValue / 12);
      set_h_event_worth_annual(e.floatValue);
      // set_i_revenue_earned_monthly(e.floatValue / 12) * e_online_event_monthly);
      setChangeFromAnnual(true);
    }
  };
  const howMuchRevenueEarned = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_i_revenue_earned_monthly(e.floatValue);
      set_j_revenue_earned_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_i_revenue_earned_monthly(e.floatValue / 12);
      set_j_revenue_earned_annual(e.floatValue);
      setChangeFromAnnual(true);
    }
  };
  // setting data
  useEffect(() => {
    if (param === "ecommerce" && c_online_revenue_monthly) {
      dispatch(
        addInputData({
          c_online_revenue_monthly,
          d_online_revenue_annual,
        })
      );
    } else if (param === "event" && (e_online_event_monthly || g_event_worth_monthly || i_revenue_earned_monthly)) {
      dispatch(
        addInputData({
          e_online_event_monthly,
          f_online_event_annual,
          g_event_worth_monthly,
          h_event_worth_annual,
          i_revenue_earned_monthly,
          j_revenue_earned_annual,
        })
      );
    }
  }, [
    c_online_revenue_monthly,
    d_online_revenue_annual,
    dispatch,
    e_online_event_monthly,
    f_online_event_annual,
    g_event_worth_monthly,
    h_event_worth_annual,
    i_revenue_earned_monthly,
    j_revenue_earned_annual,
    param,
  ]);
  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Revenue"
        tooltipText={
          data?.a_client_type?.value === "event" ? (
            <span>
              <b>Revenue</b> <br />
              How much revenue did your business earn through online channels?
              <br /> <br />
              <b>Events</b> <br />
              How many events did you get through online channels?
              <br />
              <br />
              <b>Event Value</b> <br />
              What is each event worth to you? <br /> <br />
              <b>Estimated Revenue</b> <br />
              Estimated revenue earned from events
            </span>
          ) : (
            "How much revenue did your business earn through online channels?"
          )
        }
        tooltipId="revTooltip"
      />

      <div>
        <div
          className={`${
            data?.a_event_type?.value === "ecommerce" || data?.a_client_type?.value === "event" ? "hidden" : "block"
          }`}
        >
          <div className="flex justify-between input-row">
            <div className=" w-full">
              <p className="input-label">Monthly</p>
              <RevenueInputMonthly
                temp={temp}
                setTemp={setTemp}
                handleOnlineRevenue={handleOnlineRevenueMonthly}
                c_online_revenue_monthly={c_online_revenue_monthly}
                setC_online_revenue_monthly={setC_online_revenue_monthly}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
              />
            </div>

            <div className="w-full">
              <p className="input-label">Annual</p>
              <RevenueInputAnnual
                temp={temp}
                setTemp={setTemp}
                handleOnlineRevenue={handleOnlineRevenueAnnual}
                d_online_revenue_annual={d_online_revenue_annual}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
              />
            </div>
          </div>
        </div>
        {data?.a_client_type?.value === "event" && (
          <>
            <div className="w-full">
              <div className="flex justify-between input-row">
                <div className="w-full">
                  <p className="input-label">Monthly Events</p>
                  <OnlineChannelInputMonthly
                    setTemp={setTemp}
                    handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                    handleHowManyEvents={handleHowManyEvents}
                    setChangeFromAnnual={setChangeFromAnnual}
                    e_online_event_monthly={e_online_event_monthly}
                  />
                </div>
                <div className="w-full">
                  <p className="input-label">Annual Events</p>
                  <OnlineChannelInputAnnual
                    setTemp={setTemp}
                    handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                    handleHowManyEvents={handleHowManyEvents}
                    setChangeFromMonthly={setChangeFromMonthly}
                    f_online_event_annual={f_online_event_annual}
                  />
                </div>
              </div>
            </div>

            <div className={`gap-2 mt-6 mb-6 md:mb-0`}>
              <p className="col-span-4 text-xs md:text-left input-label">Event Value</p>
              {/* <p className="col-span-4 text-left md:hidden">Annual</p> */}

              <EventWorthInputMonthly
                setTemp={setTemp}
                setChangeFromAnnual={setChangeFromAnnual}
                handleHowMuchEventGrowth={handleHowMuchEventGrowth}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                g_event_worth_monthly={g_event_worth_monthly}
              />
              {/* <EventWorthInputAnnual
                setTemp={setTemp}
                setChangeFromMonthly={setChangeFromMonthly}
                handleHowMuchEventGrowth={handleHowMuchEventGrowth}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                h_event_worth_annual={h_event_worth_annual}
              /> */}
            </div>

            <div className=" gap-2 mt-6">
              <div className="flex justify-between input-row no-wrap-mobile">
                <div className=" w-full">
                  <p className="col-span-4 text-xs md:text-left input-label">Est. Monthly Revenue</p>
                  <RevenueEarnedInputMonthly
                    setTemp={setTemp}
                    handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                    howMuchRevenueEarned={howMuchRevenueEarned}
                    setChangeFromAnnual={setChangeFromAnnual}
                    i_revenue_earned_monthly={i_revenue_earned_monthly}
                  />
                </div>

                <div className="w-full">
                  <p className="col-span-4 text-xs md:text-left input-label">Est. Annual Revenue</p>
                  <RevenueEarnedInputAnnual
                    setTemp={setTemp}
                    handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                    howMuchRevenueEarned={howMuchRevenueEarned}
                    setChangeFromMonthly={setChangeFromMonthly}
                    j_revenue_earned_annual={j_revenue_earned_annual}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OnlineRevenueSection;
