import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { addInputData } from "../../redux/slice/inputSlice";
import ClientTypeSection from "./Sections/ClientTypeSection";
import FinancingMethodSection from "./Sections/FinancingMethodSection";
import MarketingAgencySection from "./Sections/MarketingAgencySection";
import OnlineAdvertisingSection from "./Sections/OnlineAdvertisingSection";
import OnlineRevenueSection from "./Sections/OnlineRevenueSection";
import Lottie from "react-lottie";

import downloadIcon from "../../Assets/download-icon.svg";
import savedIcon from "../../Assets/saved-icon.svg";
import savingAnimation from "../../Assets/savingAnimation.json";
import InputSectionHeader from "./InputSectionHeader";

const Input = ({ roasStatusQuo, isECommPage }) => {
  const [searchParams] = useSearchParams();
  const param = searchParams.get("type");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [inputEmail, setInputEmail] = useState("");

  const token = searchParams.get("token");
  const data = useSelector((s) => s.data);

  const isMobile = window.innerWidth <= 750;

  const email = searchParams.get("email");
  if (email) {
    localStorage.setItem("email", email);
  }

  let [requestData, setRequestData] = useState({});

  // 0: Not Saved, 1: Saving, 2: Saved
  const [savingState, setSavingState] = useState(0);

  const dispatch = useDispatch();

  const { v2Landing, fullCalc } = useSelector((s) => s.ui);

  const BASE_URL = "https://dbt-development-325613.uc.r.appspot.com/api/v1/";

  const checkEmailExists = useCallback(() => {
    const email = localStorage.getItem("email") || requestData?.email;
    setShowEmailInput(!email);

    return !!email;
  }, [requestData]);

  const openBookingPage = () => {
    if (isECommPage) {
      let message = { type: "action", action: "bookDemo", page: "miniSavings" };
      window.top?.postMessage(message, "*");
    } else {
      window.open("https://www.revenueroll.com/book-demo");
    }
  };

  const saveData = (redirect = false) => {
    const email =
      inputEmail || localStorage.getItem("email") || requestData?.email;

    if (!fullCalc && !inputEmail) {
      return;
    }

    if (!email) {
      return setShowEmailInput(true);
    }

    setShowEmailInput(false);

    if (savingState === 0) {
      setSavingState(1);
      const postURL = `${BASE_URL}/magic-link/calculator/request`;

      const dataToPost = {
        client_type: data.a_client_type,
        event_type: data.b_event_type,
        online_revenue_monthly: data.c_online_revenue_monthly,
        online_revenue_annual: data.d_online_revenue_annual,
        online_event_monthly: data.e_online_event_monthly,
        online_event_annual: data.f_online_event_annual,
        event_worth_monthly: data.g_event_worth_monthly,
        event_worth_annual: data.h_event_worth_annual,
        revenue_earned_monthly: data.i_revenue_earned_monthly,
        revenue_earned_annual: data.j_revenue_earned_annual,
        online_ad_cost_monthly: data.k_online_ad_cost_monthly,
        online_ad_cost_annual: data.l_online_ad_cost_annual,
        financial_method: data.m_financial_method,
        financial_interest_rate_monthly: data.n_financial_interest_rate_monthly,
        financial_interest_rate_annual: data.o_financial_interest_rate_annual,
        marketing_agency_cost_monthly: data.p_marketing_agency_cost_monthly,
        marketing_agency_cost_annual: data.q_marketing_agency_cost_annual,
        ad_spend_with_rr: data.r_ad_spend_with_rr,
        roas_status_quo: roasStatusQuo,
        email: email,
      };

      axios
        .post(postURL, { ...dataToPost })
        .then(() => {
          setSavingState(2);

          localStorage.setItem("email", email);
          setShowEmailInput(false);

          if (redirect) {
            setSavingState(0);
            return redirectToApp();
          }

          setTimeout(() => {
            setSavingState(0);
          }, 3000);
        })
        .catch(() => setSavingState(0));
    }
  };

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: savingAnimation,
  };

  const redirectToApp = () => {
    window.open("https://www.revenueroll.com/book-demo");
  };

  // To change the height of parent page upon any changes in inputs
  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  });

  useEffect(() => {
    const c = searchParams.get("c_online_revenue_monthly");
    const e = Number(searchParams.get("e_online_event_monthly"));
    const g = Number(searchParams.get("g_event_worth_monthly"));
    const i = Number(searchParams.get("i_revenue_earned_monthly"));
    const ga = Number(searchParams.get("ga_measure_success_pointer"));

    const dataToDispatch = {};

    if (c) {
      dataToDispatch.c_online_revenue_monthly = c;
    }
    if (e) {
      dataToDispatch.e_online_event_monthly = e;
    }
    if (g) {
      dataToDispatch.g_event_worth_monthly = g;
    }
    if (i) {
      dataToDispatch.i_revenue_earned_monthly = i;
    }
    if (ga) {
      dataToDispatch.k_online_ad_cost_monthly = ga;
    }

    if (dataToDispatch !== {}) {
      dispatch(addInputData(dataToDispatch));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (token) {
      const GET_ENDPOINT = `${BASE_URL}/magic-link/calculator/validation/${token}`;
      axios.post(GET_ENDPOINT).then((res) => {
        if (!res) {
          return;
        }
        setRequestData({ ...res.data });

        const responseData = res.data;

        if (!responseData.client_type) {
          return;
        }

        const dataToDispatch = {
          a_client_type: responseData.client_type,
          b_event_type: responseData.event_type,
          c_online_revenue_monthly: responseData.online_revenue_monthly,
          d_online_revenue_annual: responseData.online_revenue_annual,
          e_online_event_monthly: responseData.online_event_monthly,
          f_online_event_annual: responseData.online_event_annual,
          g_event_worth_monthly: responseData.event_worth_monthly,
          h_event_worth_annual: responseData.event_worth_annual,
          i_revenue_earned_monthly: responseData.revenue_earned_monthly,
          j_revenue_earned_annual: responseData.revenue_earned_annual,
          k_online_ad_cost_monthly: responseData.online_ad_cost_monthly,
          l_online_ad_cost_annual: responseData.online_ad_cost_annual,
          m_financial_method: responseData.financial_method,
          n_financial_interest_rate_monthly:
            responseData.financial_interest_rate_monthly,
          o_financial_interest_rate_annual:
            responseData.financial_interest_rate_annual,
          p_marketing_agency_cost_monthly:
            responseData.marketing_agency_cost_monthly,
          q_marketing_agency_cost_annual:
            responseData.marketing_agency_cost_annual,
          r_ad_spend_with_rr: responseData.ad_spend_with_rr,
        };

        dispatch(addInputData(dataToDispatch));
      });
    }
  }, [token, dispatch]);

  useEffect(() => {
    checkEmailExists();
  }, [checkEmailExists]);

  return (
    <>
      {fullCalc && (
        <div className="lg:col-span-2 md:col-span-3 md:mb-4 input-card">
          <ClientTypeSection
            className="input-section client-type"
            param={param}
          />
          <OnlineRevenueSection
            className="input-section online-rev"
            param={param}
          />
          <OnlineAdvertisingSection className="input-section online-ads" />
          <FinancingMethodSection className="input-section fin-method" />
          <MarketingAgencySection className="input-section marketing-agency" />

          {/* Save Your Results For Later Button */}

          <div className="save-results input-section">
            <InputSectionHeader headerName="Save Your Results For Later" />

            {showEmailInput && (
              <div className="confirm-email-wrapper">
                <p className="section-description">
                  Enter your email address below and we'll save your results for
                  later
                </p>

                <p className="input-label">E-mail</p>
                {/* <div className="input-field"> */}
                <input
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                  className="email-input input-field"
                  placeholder="e.g. jane.cooper@gmail.com"
                />
                {/* </div> */}
                <button
                  className="download-btn confirm-email-btn"
                  onClick={saveData}
                >
                  <img src={downloadIcon} alt="Download" />
                  Save
                </button>
              </div>
            )}
            {!showEmailInput && (
              <button
                className={
                  "download-btn" + (savingState === 1 ? " saving" : "")
                }
                onClick={saveData}
                disabled={showEmailInput}
              >
                {savingState === 0 && (
                  <>
                    <img src={downloadIcon} alt="Download" />
                    {isMobile
                      ? "Download Results"
                      : "Save Your Results For Later"}
                  </>
                )}
                {savingState === 1 && (
                  <>
                    <Lottie options={animationOptions} height={40} width={40} />{" "}
                    Saving
                  </>
                )}
                {savingState === 2 && (
                  <>
                    <img src={savedIcon} alt="Saved" /> Saved
                  </>
                )}
              </button>
            )}

            <p className="disclaimer-text">
              These are estimates. Actual results may vary.
            </p>
          </div>
        </div>
      )}

      {!fullCalc && (
        <>
          <div className={"input-card" + (v2Landing ? " v2-landing" : "")}>
            <p className="tell-about-business">Tell Us About Your Business</p>
            <OnlineRevenueSection
              className="input-section online-rev"
              param={param}
            />
            <OnlineAdvertisingSection className="input-section online-ads" />
            <MarketingAgencySection className="input-section marketing-agency" />
            {!v2Landing && (
              <>
                <div className="confirm-email-wrapper">
                  <p className="section-heading-text">Start Growing Today</p>
                  <p className="input-label email-input-label">Email</p>
                  <div className="email-input-wrapper">
                    <input
                      value={inputEmail}
                      onChange={(e) => setInputEmail(e.target.value)}
                      type="email"
                      className="email-input"
                      placeholder="Please Enter Your Email"
                    />
                  </div>
                </div>
                <button
                  className="get-started-btn"
                  onClick={() => saveData(true)}
                >
                  {savingState === 0 ? "Get Started" : "Please Wait..."}
                </button>
              </>
            )}
            {v2Landing && (
              <div className="start-growing-v2">
                <p className="start-growing-text">Start Growing Today</p>
                <button className="get-started-btn" onClick={openBookingPage}>
                  Book Demo
                </button>
              </div>
            )}
            <p className="disclaimer-text">
              These are estimates. Actual results may vary.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Input;
