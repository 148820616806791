import React from "react";
import { FiCheck } from "react-icons/fi";
import Select, { components } from "react-select";

const InputDropdown = ({ className, options, onChange, defaultValue }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: "48px",
      border: "1px solid #C5CEE0",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #57CC99",
      },
      borderRadius: "8px",
      cursor: "pointer",
      color: "#082D45",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#082D45",
      textAlign: "left",
      fontWeight: "normal",
    }),
    menu: (base, state) => ({
      ...base,
      background: "white",
    }),

    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: "#082D45",
      fontFamily: "Gilroy",
      fontWeight: "400",
      borderBottom: "1px solid #22577A15",
      padding: "12px 16px",
      background: "white",
      "&:hover": {
        background: "#80ED991A",
      },
    }),
  };

  return (
    <div className={`app-select ${className ?? ""}`}>
      <Select
        styles={customStyles}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
          Option: (props) => (
            <components.Option
              {...props}
              children={
                <div className="option-inner">
                  <div className="label">{props.label}</div>

                  <div className="selected-icon">
                    {props.isSelected && <FiCheck />}
                  </div>
                </div>
              }
              className={
                "select-option" + (props.isSelected ? " selected" : "")
              }
            />
          ),
        }}
      />
    </div>
  );
};

export default InputDropdown;
