import "./App.scss";
import Layout from "./Layout";
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import IndexPage from "./components/pages/IndexPage";
import './common/chartHelper'
function App() {
  
  // Need this to set the height of iFrame in the parent page
  window.addEventListener('resize', () => {
    let message = { height: document.body.scrollHeight };	
    window.top.postMessage(message, "*");
  })

  return (
    // <div className="grid grid-cols-1 px-4 lg:px-0 lg:grid-cols-5 my-5 font-primary text-white hello">
    //   <Input className="lg:col-span-2 md:mb-4" />
    //   <Infogram className="col-span-3" />
    //   <NotificationContainer />
    // </div>
    
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          {/* <Route path="/type" element={<IndexPage />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
