import commaNumber from "comma-number";
import React from "react";
import { useSelector } from "react-redux";
import GroupBarChart from "./ChartJs/GroupBarChart";
import HalfDoughnut from "./ChartJs/HalfDoughnut";
import StackBarChart from "./ChartJs/StackBarChart";

const Infogram = ({ className, graphData }) => {
  const roi = graphData?.data?.investment_sensitivity?.current_roi;
  let NRaosImpv = [roi];
  let raosImpv = [0];
  let obj = graphData?.data?.investment_sensitivity?.investment;

  const { k_online_ad_cost_monthly } = useSelector((s) => s?.data);
  const { fullCalc } = useSelector((s) => s.ui);

  if (obj !== null && obj !== undefined) {
    Object?.keys(obj)
      ?.map((key) => obj[key])
      ?.forEach((sig) => {
        NRaosImpv?.push(+sig?.same);
        raosImpv?.push(+sig?.roas);
      });
  }

  const GroupBar = {
    NRaosImpv: NRaosImpv.slice(0, NRaosImpv.length - 1),
    raosImpv: raosImpv.slice(0, raosImpv.length - 1),
  };

  const contractorDT = graphData?.data?.minimize_contractor_cost?.contractor;
  const revenueDT = graphData?.data?.minimize_contractor_cost?.revenue;

  const stackedBar = {
    labels: ["Status Quo", "Same ROAS", "Improved ROAS"],
    revenue: [
      revenueDT?.status_quo,
      revenueDT?.same_roas,
      revenueDT?.improved_roas,
    ],
    ccost: [
      contractorDT?.status_quo,
      contractorDT?.same_roas,
      contractorDT?.improved_roas,
    ],
  };

  const statusQuo =
    graphData?.data?.improved_ad_performance?.status_quo?.toFixed(1);
  const analytics =
    graphData?.data?.improved_ad_performance?.uplift_analytics_platform?.toFixed(
      1
    );
  const manage =
    graphData?.data?.improved_ad_performance?.uplift_managed?.toFixed(1);
  const DoughnutData = [statusQuo, analytics, manage];
  const DoughnutDataToShow = [
    statusQuo,
    analytics - statusQuo,
    manage - analytics,
  ];

  const blurb1 = graphData?.data?.minimize_contractor_cost?.blurb1;
  const blurb2 = graphData?.data?.minimize_contractor_cost?.blurb2;

  const formatCommaNumber = (number) =>
    isNaN(number) ? "-" : commaNumber(Number(number).toFixed(0));

  let financingAmount =
    +statusQuo >= 2 ? 2 * k_online_ad_cost_monthly : k_online_ad_cost_monthly;
  financingAmount = formatCommaNumber(financingAmount);

  return (
    <div className={`${className ?? ""}`}>
      <div>
        <div>
          <div></div>
          <div>
            <div className="chart-div roas-chart-div">
              <HalfDoughnut
                temp={DoughnutData}
                showTemp={DoughnutDataToShow}
                fullCalc={fullCalc}
              />
            </div>
            <div className="chart-div">
              <StackBarChart
                labels={stackedBar.labels}
                revenue={stackedBar.revenue}
                ccost={stackedBar.ccost}
                blurb1={blurb1}
                blurb2={blurb2}
                fullCalc={fullCalc}
              />
            </div>
            {fullCalc && (
              <div className="chart-div group-chart-div">
                <GroupBarChart
                  NRaosImpv={GroupBar.NRaosImpv}
                  raosImpv={GroupBar.raosImpv}
                  financingAmount={financingAmount}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Infogram;
