import React from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addInputData } from "../../../redux/slice/inputSlice";

const RASInput = () => {
  const [r_ad_spend_with_rr, set_r_ad_spend_with_rr] = useState(200);
  const data = useSelector((state) => state?.data);
  const dispatch = useDispatch();
  const r = data?.r_ad_spend_with_rr;
  const sendRasData = () => {
    // && r_ad_spend_with_rr >= 100
    if (r !== r_ad_spend_with_rr) {
      dispatch(addInputData({ r_ad_spend_with_rr }));
    }
  };

  // if (r_ad_spend_with_rr < 100) {
  //   console.log("calling");
  //   NotificationManager.warning(
  //     "",
  //     "Please give a valid number above 100",
  //     1000
  //   );
  // }
  return (
    <>
      <div
        className={`col-span-2  px-2 input-field ras-input`}
      >
        <NumberFormat
          thousandsGroupStyle="thousand"
          suffix={" %"}
          decimalSeparator="."
          displayType="input"
          type="text"
          thousandSeparator={true}
          allowNegative={true}
          className={`bg-inherit w-full h-full focus:outline-none`}
          onValueChange={(e) => {
            set_r_ad_spend_with_rr(e.floatValue);
          }}
          value={r_ad_spend_with_rr}
          decimalScale={2}
          onBlur={() => {
            sendRasData();
          }}
        />
      </div>
    </>
  );
};

export default RASInput;
