import { getRelativePosition } from "chart.js/helpers";
import React, { useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

const HalfDoughnut = ({ temp, showTemp, blurb1 }) => {
  const chartRef = useRef();

  const [hovered, setHovered] = useState({ sq: false, ua: false, um: false });
  const [dataToShow, setDataToShow] = useState({ label: "", value: "" });

  const { v2Landing } = useSelector((s) => s.ui);

  const setNoHover = () => {
    setHovered({ ua: false, um: false, sq: false });
  };

  let improveROAS = ((+temp[2] - +temp[0]) / +temp[0]).toFixed(0) * 100;
  improveROAS = isNaN(improveROAS)
    ? "-"
    : improveROAS >= 100
    ? "100"
    : improveROAS;

  const data = () => {
    if (!temp) return;
    const point1 = +temp[0];
    const point2 = +temp[1];
    const point3 = +temp[2];

    const data1 = [point1 * 0.4, point1 * 0.9, point1];
    const data2 = [
      null,
      null,
      point1,
      point1 + point2 * 0.1,
      point1 + point2 * 0.5,
    ];
    const data3 = [
      null,
      null,
      null,
      null,
      point1 + point2 * 0.5,
      point1 + point2 * 0.5 + point3 * 0.3,
      point1 + point2 * 0.5 + point3 * 0.4,
    ];

    return {
      labels: ["", "", "", "", "", "", ""],
      reDraw: false,
      datasets: [
        {
          label: "Status Quo",
          data: data1,
          backgroundColor: "#80ED99",
          borderColor: "white",
          fill: true,
          borderWidth: 7,
          tension: 0.4,
          datalabels: {
            display: false,
          },
        },
        {
          label: "Improvement From Analytics",
          data: data2,
          backgroundColor: "#57CC99",
          borderColor: "white",
          fill: true,
          borderWidth: 7,
          tension: 0.4,
          datalabels: {
            display: false,
          },
        },
        {
          label: "Improvement From Marketing Support",
          data: data3,
          backgroundColor: "#38A3A5",
          fill: true,
          borderColor: "white",
          borderWidth: 7,
          tension: 0.4,
          datalabels: {
            display: false,
          },
        },
      ],
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    onHover: (event) => {
      const {
        current: { width },
      } = chartRef;
      var { x } = getRelativePosition(event, chartRef.current);

      if (x / width < 0.3) {
        if (!hovered.sq) {
          setHovered({ sq: true, ua: false, um: false });
          setDataToShow({ label: "", value: `${temp[0]}x` });
        }
      } else if (x / width < 0.66) {
        if (!hovered.ua) {
          setHovered({ sq: false, um: false, ua: true });
          setDataToShow({
            label: "Improvement From Analytics: ",
            value: `${temp[1]}x`,
          });
        }
      } else if (x / width > 0.66) {
        if (!hovered.um) {
          setHovered({ sq: false, um: true, ua: false });
          setDataToShow({
            label: "Improvement From Marketing Support: ",
            value: `${temp[2]}x`,
          });
        }
      }
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        beginsAtZero: true,
        min: 0,
      },
      y: {
        display: false,
        min: 0,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    animation: {
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default") {
          delay = context.dataIndex * 1000 + context.datasetIndex * 500;
        }
        return delay;
      },
    },
  };

  const redirectToApp = () => {
    window.open("https://www.revenueroll.com/book-demo");
  };

  return (
    <div className="">
      <div className="w-full top-8 grid grid-cols-2 space-x-2 "></div>
      <div className="flex items-center chart-heading-wrapper">
        <div className="sub-heading-wrapper">
          <p className="sub-heading">Revenue Roll Improves ROAS</p>
        </div>
        <div className="group relative">
          <div className="">
            <div className="info-icon">
              <p>!</p>
            </div>

            <div className="hidden group-hover:block">
              <div className="section-info-div">
                <ul>
                  <li>
                    Revenue Roll can grow your revenue from advertising by as
                    much as 100%
                  </li>
                  <li>
                    We maximize your revenue earned from advertising dollars
                    using our innovative analytics platform.
                  </li>
                  <li>
                    We have far superior analytics tools that are typically only
                    available to enterprise clients at very high prices.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!v2Landing && (
        <div className="box-btn-wrapper">
          <div className="number-box">
            <div className="box-content">
              <p>Increase Your ROAS By</p>
              <p className="box-value">{improveROAS}%</p>
              <p>By Working With Us</p>
            </div>
          </div>
          <button className="box-btn" onClick={redirectToApp}>
            Start Growing
          </button>
        </div>
      )}

      <div className="area-chart-wrapper relative">
        <div className="area-chart-overlays">
          <div
            className={
              "ac-st-quo-overlay" + (hovered.sq ? " hovered hv-st-quo" : "")
            }
          >
            <p>Status Quo</p>
            {hovered.sq && (
              <>
                <p className="hover-text-title">{dataToShow.label}</p>
                <p className="hover-text-value">{dataToShow.value}</p>
              </>
            )}
          </div>
          <div
            className={
              "ac-rr-man-overlay" +
              (hovered.ua
                ? " hovered hv-analytics"
                : hovered.um
                ? " hovered hv-marketing"
                : "")
            }
          >
            <p>Revenue Roll</p>
            {(hovered.ua || hovered.um) && (
              <>
                <p className="hover-text-title">{dataToShow.label}</p>
                <p className="hover-text-value">{dataToShow.value}</p>
              </>
            )}
          </div>
        </div>
        <Line
          data={data()}
          onMouseOut={setNoHover}
          ref={chartRef}
          options={options}
        />
      </div>
      <div className="chart-legends">
        {data().datasets.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <div className="legend">
                <div
                  className="color-box"
                  style={{ background: d.backgroundColor }}
                ></div>{" "}
                <p>{d.label}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default HalfDoughnut;
