import React, { useEffect, useState } from "react";

import InputSectionHeader from "../../InputSectionHeader";
import { useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import { useSelector } from "react-redux";
import InterestRateInputMonthly from "../../../InputSections/FinancingInput/InterestRateInputMonthly";
import InterestRateInputAnnual from "../../../InputSections/FinancingInput/InterestRateInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { PillSelector } from "../../PillSelector";

const first_dropdown_options = [
  { value: "cash", label: "Cash" },
  { value: "borrowed", label: "Borrow" },
];

const FinancingMethodSection = ({ className }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.data);
  const [temp, setTemp] = useState(0);

  const [
    n_financial_interest_rate_monthly,
    set_n_financial_interest_rate_monthly,
  ] = useState(2);
  const [
    o_financial_interest_rate_annual,
    set_o_financial_interest_rate_annual,
  ] = useState(n_financial_interest_rate_monthly * 12);
  // Checking input field monthly and annually changes
  const [changeFromMonthly, setChangeFromMonthly] = useState(false);
  const [changeFromAnnual, setChangeFromAnnual] = useState(false);

  // Sending input data to redux
  const handleFinantialInterestDataUpdate = () => {
    if (
      data?.n_financial_interest_rate_monthly !==
        n_financial_interest_rate_monthly ||
      data?.o_financial_interest_rate_annual !==
        o_financial_interest_rate_annual
    ) {
      dispatch(
        addInputData({
          n_financial_interest_rate_monthly,
          o_financial_interest_rate_annual,
        })
      );
    }
  };
  const handleFinancialInterest = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_n_financial_interest_rate_monthly(e.floatValue);
      set_o_financial_interest_rate_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_n_financial_interest_rate_monthly(e.floatValue / 12);
      set_o_financial_interest_rate_annual(e.floatValue);
      setChangeFromAnnual(true);
    }
  };
  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorFour: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorFour: false }));
    }
  }, [temp, dispatch]);

  useEffect(() => {
    set_n_financial_interest_rate_monthly(
      data.n_financial_interest_rate_monthly
    );
  }, [data.n_financial_interest_rate_monthly]);

  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Financing"
        tooltipId="finTooltip"
        tooltipText="Do you pay for advertising with cash or borrowed funds (like a loan or a credit card)?"
      />

      <div>
        {/* first drop down input */}

        <div className="gap-2">
          <PillSelector
            options={first_dropdown_options}
            defaultValue={data?.m_financial_method}
            onChange={(e) => dispatch(addInputData({ m_financial_method: e }))}
          />
        </div>
        <div
          className={`${
            data?.m_financial_method?.value === "cash" ? "hidden" : "block"
          }`}
        >
          <div className={`mt-2 `}>
            <p className="section-description">Effective Interest Rate</p>

            <div className="flex justify-between input-row">
              <div className=" w-full">
                <p className="input-label">Monthly</p>
                <InterestRateInputMonthly
                  setTemp={setTemp}
                  handleFinancialInterest={handleFinancialInterest}
                  handleFinantialInterestDataUpdate={
                    handleFinantialInterestDataUpdate
                  }
                  setChangeFromAnnual={setChangeFromAnnual}
                  n_financial_interest_rate_monthly={
                    n_financial_interest_rate_monthly
                  }
                />
              </div>

              <div className="w-full">
                <p className="input-label">Annual</p>
                <InterestRateInputAnnual
                  setTemp={setTemp}
                  setChangeFromMonthly={setChangeFromMonthly}
                  handleFinancialInterest={handleFinancialInterest}
                  handleFinantialInterestDataUpdate={
                    handleFinantialInterestDataUpdate
                  }
                  o_financial_interest_rate_annual={
                    o_financial_interest_rate_annual
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancingMethodSection;
