import commaNumber from "comma-number";
import React, { useEffect, useMemo, useState } from "react";

export const Table = ({ tableData }) => {
    const [colSpanCount, setColSpanCount] = useState({
        same_roas: 2,
        improved_roas: 3,
    });

    const formatData = (data, fieldName) => {
        if (data === "Infinity" || data === "-Infinity") {
            return `-`;
        }

        let toReturn = 0;
        if (data > 0) {
            if (
                fieldName === "roas" ||
                fieldName === "return_on_campaign" ||
                fieldName === "Contractor_fees"
            ) {
                toReturn = commaNumber(Number(data).toFixed(1));
            } else {
                toReturn = commaNumber(Number(data).toFixed(0));
            }
        } else if (data < 0) {
            if (
                fieldName === "roas" ||
                fieldName === "return_on_campaign" ||
                fieldName === "Contractor_fees"
            ) {
                toReturn = `(${commaNumber((Number(data) * -1).toFixed(1))})`;
            } else {
                toReturn = `(${commaNumber((Number(data) * -1).toFixed(0))})`;
            }
        } else {
            if (fieldName === "customer_ad") {
                toReturn = 0;
            } else return "-";
        }

        if (fieldName === "roas") {
            return `${toReturn}x`;
        } else if (
            ["return_on_campaign", "Contractor_fees"].includes(fieldName)
        ) {
            return `${toReturn}%`;
        }

        return toReturn;
    };

    const COLS = useMemo(() => {
        return {
            same_roas: [
                { name: "No Financing", value: "unlevered", show: true },
                { name: "100% Financing", value: "levered100", show: true },
            ],
            improved_roas: [
                { name: "No Financing", value: "unlevered", show: true },
                { name: "100% Financing", value: "levered100", show: true },
                { name: "200% Financing", value: "levered200", show: true },
            ],
        };
    }, []);

    useEffect(() => {
        let sameCount = 0,
            impCount = 0;

        for (let c of COLS.same_roas) {
            sameCount += c.show ? 1 : 0;
        }
        for (let c of COLS.improved_roas) {
            impCount += c.show ? 1 : 0;
        }

        setColSpanCount({ same_roas: sameCount, improved_roas: impCount });
    }, [tableData, COLS]);

    const isSafari =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const categories = [
        {
            name: "Pre-Campaign",
            children: [
                {
                    name: "Advertising Spend",
                    totalRow: false,
                    value: "ad_spend",
                },
                {
                    name: "Less: Loan From Current Sources",
                    totalRow: false,
                    value: "loan_from_traditional_sources",
                },
                {
                    name: "Less: Loan From Revenue Roll",
                    totalRow: false,
                    value: "loan_from_revenue_roll",
                },
                {
                    name: "Customer Ad Spend Outlay",
                    totalRow: true,
                    value: "customer_ad_spend_outlay",
                    formatField: "customer_ad",
                },
                {
                    name: "Plus: Other Marketing Costs",
                    totalRow: false,
                    value: "current_agency",
                },
                {
                    name: "Plus: Revenue Roll SaaS Analytics Cost",
                    totalRow: false,
                    value: "revenue_roll_saas_analytics_cost",
                },
                {
                    name: "Total Pre-Campaign Cash Investment",
                    totalRow: true,
                    value: "total_pre_campaign_cash_investment",
                },
            ],
        },
        {
            name: "Campaign Returns",
            children: [
                {
                    name: "Advertising Spend",
                    totalRow: false,
                    value: "ad_spend",
                },
                {
                    name: "ROAS",
                    totalRow: false,
                    value: "roas",
                    fieldName: "roas",
                },
                {
                    name: "Online Revenue Generated",
                    totalRow: true,
                    value: "online_revenue_generated",
                },
                {
                    name: "Less: Pre-Campaign Cash Investment",
                    totalRow: false,
                    value: "pre_campaign_cash_investment",
                },
                {
                    name: "Less: Loan Payback",
                    totalRow: false,
                    value: "loan_payback",
                },
                {
                    name: "Less: Financing Costs",
                    totalRow: false,
                    value: "financial_costs",
                },
                {
                    name: "Less: Revenue Roll Performance Fee",
                    totalRow: false,
                    value: "revenue_roll_performance_fee",
                },
                {
                    name: "Net Campaign Return",
                    totalRow: true,
                    value: "net_campaign_return",
                },
                {
                    name: "Return on Campaign Investment",
                    totalRow: false,
                    value: "return_on_campaign_investment",
                    fieldName: "return_on_campaign",
                },
                {
                    name: "Contractor Fees as % of Revenue",
                    totalRow: false,
                    value: "contractor_fees_of_revenue",
                    fieldName: "Contractor_fees",
                },
            ],
        },
    ];

    return (
        <div className={"app-table" + (isSafari ? ' safari' : '')}>
            <table align="center" cellPadding="10px">
                <thead className="relative">
                    <tr>
                        <th className="empty-space" rowSpan={3}></th>
                        <th className="st-quo-header title-cell" rowSpan={3}>
                            Status Quo
                        </th>
                        <th
                            className="rev-man-header same-roas title-cell"
                            colSpan={5}
                        >
                            RevenueRoll
                        </th>
                    </tr>

                    <tr>
                        <th
                            className="same-roas title-cell main-roas-title"
                            colSpan={colSpanCount.same_roas}
                        >
                            Same ROAS
                        </th>
                        <th
                            className="same-roas imp-roas-main-header title-cell"
                            colSpan={colSpanCount.improved_roas}
                        >
                            <span className="improved-roas-color improved-roas-main-header w-full">
                                Improved ROAS
                            </span>
                        </th>
                    </tr>

                    <tr>
                        {COLS.same_roas.map(
                            (c, i) =>
                                c.show && (
                                    <th className={"table-cell same-roas" + (i > 0 ? " td-border" : "")} key={i}>
                                        {i > 0 && (
                                            <div className={"th-border"}></div>
                                        )}
                                        {c.name}
                                    </th>
                                )
                        )}

                        {COLS.improved_roas.map(
                            (c, i) =>
                                c.show && (
                                    <th
                                        className={
                                            "table-cell improved-roas-color" +
                                            (i > 0 ? " td-border" : "") +
                                            (i === COLS.improved_roas.length - 1
                                                ? " last-column"
                                                : "")
                                        }
                                        key={i}
                                    >
                                        {i > 0 && (
                                            <div className={"th-border"}></div>
                                        )}
                                        {c.name}
                                    </th>
                                )
                        )}
                    </tr>
                </thead>

                {categories.map((category, catIndex) => {
                    return (
                        <tbody key={catIndex} className="relative">
                            <tr className="title-row">
                                <td className="table-category">
                                    {category.name}
                                </td>
                                <td className="table-cell st-quo"></td>

                                {COLS.same_roas.map(
                                    (col, colIndex) =>
                                        col.show && (
                                            <td
                                                key={colIndex}
                                                className="table-cell same-roas"
                                            ></td>
                                        )
                                )}

                                {/* Improved ROAS */}
                                {COLS.improved_roas.map(
                                    (col, colIndex) =>
                                        col.show && (
                                            <td
                                                key={colIndex}
                                                className={
                                                    "table-cell improved-roas-color" +
                                                    (colIndex ===
                                                    COLS.improved_roas.length -
                                                        1
                                                        ? " last-column"
                                                        : "")
                                                }
                                            ></td>
                                        )
                                )}
                            </tr>

                            {category.children.map((row, rowIndex) => {
                                return (
                                    <React.Fragment key={rowIndex}>
                                        <tr
                                            className={
                                                (row.totalRow
                                                    ? "total-row"
                                                    : "") +
                                                (rowIndex ===
                                                category.children.length - 1
                                                    ? " last-row"
                                                    : "")
                                            }
                                        >
                                            <td className="rowName">
                                                {row.name}
                                            </td>
                                            {/* Status Quo */}
                                            <td className="table-cell st-quo">
                                                {/* {tableData?.status_quo[row.value]} */}
                                                {formatData(
                                                    tableData?.status_quo?.[
                                                        row.value
                                                    ],
                                                    row.fieldName
                                                )}
                                            </td>

                                            {/* Same ROAS */}
                                            {COLS.same_roas.map(
                                                (col, colIndex) =>
                                                    col.show && (
                                                        <td
                                                            key={colIndex}
                                                            className={
                                                                "table-cell same-roas" +
                                                                (colIndex > 0 ? " td-border" : "")
                                                            }
                                                        >
                                                            {colIndex > 0 &&
                                                                rowIndex ===
                                                                    0 && (
                                                                    <div
                                                                        className={
                                                                            "cell-border body" +
                                                                            catIndex
                                                                        }
                                                                    ></div>
                                                                )}
                                                            {formatData(
                                                                tableData
                                                                    ?.same_roas?.[
                                                                    col.value
                                                                ]?.[row.value],
                                                                row.fieldName
                                                            )}
                                                        </td>
                                                    )
                                            )}

                                            {/* Improved ROAS */}
                                            {COLS.improved_roas.map(
                                                (col, colIndex) =>
                                                    col.show && (
                                                        <td
                                                            key={colIndex}
                                                            className={
                                                                "table-cell improved-roas-color" +
                                                                (colIndex > 0 ? " td-border" : "") +
                                                                (colIndex ===
                                                                COLS
                                                                    .improved_roas
                                                                    .length -
                                                                    1
                                                                    ? " last-column"
                                                                    : "") +
                                                                (rowIndex ===
                                                                    category
                                                                        .children
                                                                        .length -
                                                                        1 &&
                                                                colIndex ===
                                                                    COLS
                                                                        .improved_roas
                                                                        .length -
                                                                        1 &&
                                                                catIndex !== 0
                                                                    ? " table-last-cell"
                                                                    : "")
                                                            }
                                                        >
                                                            {colIndex > 0 &&
                                                                rowIndex ===
                                                                    0 && (
                                                                    <div
                                                                        className={
                                                                            "cell-border body" +
                                                                            catIndex
                                                                        }
                                                                    ></div>
                                                                )}

                                                            {formatData(
                                                                tableData
                                                                    ?.improved_roas?.[
                                                                    col.value
                                                                ]?.[row.value],
                                                                row.fieldName
                                                            )}
                                                        </td>
                                                    )
                                            )}
                                        </tr>
                                        {rowIndex ===
                                            category.children.length - 1 &&
                                            catIndex === 0 && (
                                                <tr className="empty-row">
                                                    <td className="rowName"></td>
                                                    <td className="table-cell st-quo"></td>

                                                    {/* Same ROAS */}
                                                    {COLS.same_roas.map(
                                                        (c, i) =>
                                                            c.show && (
                                                                <td
                                                                    key={i}
                                                                    className="table-cell same-roas"
                                                                ></td>
                                                            )
                                                    )}

                                                    {/* Improved ROAS */}
                                                    {COLS.improved_roas.map(
                                                        (c, i) =>
                                                            c.show && (
                                                                <td
                                                                    key={i}
                                                                    className={
                                                                        "table-cell improved-roas-color" +
                                                                        (i ===
                                                                        COLS
                                                                            .improved_roas
                                                                            .length -
                                                                            1
                                                                            ? " last-column"
                                                                            : "")
                                                                    }
                                                                ></td>
                                                            )
                                                    )}
                                                </tr>
                                            )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    );
                })}
            </table>
        </div>
    );
};
