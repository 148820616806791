import { toFont } from "chart.js/helpers";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
Chart.defaults.font.family = "Gilroy";
Chart.defaults.scale.grid.borderWidth = 0;
Chart.defaults.plugins.tooltip.titleColor = "#22577A";
Chart.defaults.plugins.tooltip.usePointStyle = true;
Chart.defaults.plugins.tooltip.backgroundColor = "white";
Chart.defaults.plugins.tooltip.bodyColor = "#151A30";
Chart.defaults.plugins.tooltip.bodyFont.size = 20;

const isMobile = window.innerWidth <= 750;

export function externalTooltip(context, v2Landing = false) {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.innerHTML = `<table></table>`;
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.visibility = "hidden";
        return;
    }

    const { xAlign, yAlign } = tooltipModel;

    // Set caret Position
    tooltipEl.classList = [];
    if (yAlign) {
        tooltipEl.classList.add(yAlign);
    }

    if (!["top", "bottom"].includes(yAlign) && xAlign) {
        tooltipEl.classList.add(xAlign);
    }
    if (!yAlign && !xAlign) {
        tooltipEl.classList.add("no-transform");
    }

    // Set Text
    if (tooltipModel.body) {
        const titleLines = tooltipModel.title || [];
        const bodyLines = tooltipModel.body.map((bodyItem) => bodyItem.lines);

        let innerHtml = "<thead>";

        titleLines.forEach((title) => {
            innerHtml += `<tr><th style="text-align: left; color: #22577A; font-size: 16px; font-weight: bold">${title}</th></tr>`;
        });
        innerHtml += `</thead><tbody>`;

        bodyLines.forEach(function (body, i) {
            if (!body[0]) {
                return;
            }
            const colors = tooltipModel.labelColors[i];
            let style = "background:" + colors.borderColor;
            style += "; border-color:" + colors.borderColor;
            style += "; border-radius: 50%";
            style += "; width: 16px";
            style += "; height: 16px";
            style += "; display: inline-block";
            style += "; margin-right: 12px";

            const span = '<span style="' + style + '"></span>';
            const updatedBody = body.map((b) => {
                const [label, value] = b.split(":");
                const labelElement = `<span style="color: #151A30">${label}</span>`;
                const valueElement = `<span style="color: #22577A; margin-left: 12px"> <b> ${value} </b> </span>`;
                return `${labelElement}: ${valueElement}`;
            });
            innerHtml += `<tr><td class="flex align-center" style="font-size: 16px; margin-top: 20px">${span} ${updatedBody} </td></tr>`;
        });
        innerHtml += "</tbody>";

        let tableRoot = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    const bodyFont = toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.display = "block";

    tooltipEl.style.visibility = "visible";
    tooltipEl.style.filter = "drop-shadow(10px 11px 41px rgba(67, 77, 109, 0.14))";
    tooltipEl.style.padding = "12px";
    tooltipEl.style.transitionDuration = "200ms";
    tooltipEl.style.borderRadius = "16px";
    tooltipEl.style.background = "white";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.whiteSpace = "nowrap";
    tooltipEl.style.clear = "both";

    if (yAlign !== "center") {
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.x + "px";
    } else if (xAlign === "left") {
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.x + 60 + "px";
    } else if (xAlign === "right") {
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.x - 60 + "px";
    }
    if (yAlign === "bottom") {
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.y - ( v2Landing ? 80 : 50) + "px";
    } else {
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.y + 30 + "px";
    }
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.pointerEvents = "none";

    const elementToDelete = document.head.querySelector("#styleToDelete");
    if (elementToDelete) {
        document.head.removeChild(elementToDelete);
    }
    let styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.id = "styleToDelete";
    let caretXPosition = tooltipModel.caretX - tooltipModel.x - 15;
    if (isMobile) {
        if (xAlign !== "left") {
            caretXPosition = tooltipModel.caretX - tooltipModel.x - 25;
        }
    } else {
        if (xAlign === "left") {
            caretXPosition = tooltipModel.caretX - tooltipModel.x - 15;
        } else {
            caretXPosition = tooltipModel.caretX - tooltipModel.x - 2;
        }
    }
    styleElem.innerHTML = `#chartjs-tooltip::before {left: ${caretXPosition}px;}`;

    tooltipEl.style.width = "auto";
}
