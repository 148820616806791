import React, { useRef } from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { externalTooltip } from "../../../../common/chartHelper";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

let labels;

const GroupBarChart = ({ NRaosImpv, raosImpv, financingAmount }) => {
  const fin_data = useSelector((state) => state.data);
  const financial_method = fin_data?.m_financial_method?.value;
  const [isHovered, setIsHovered] = useState(false);

  const chartRef = useRef();

  const options = {
    layout: {
      padding: {
        bottom: 10,
        top: 60,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltip,
        yAlign: "bottom",
        bodyFont: {
          size: 16,
        },
        callbacks: {
          title: (title) => {
            const {
              0: { label },
            } = title;
            return label === "Status Quo" ? label : `% Financed ${label}`;
          },
          label(tooltipItems) {
            if (tooltipItems.raw == null) {
              return null;
            }
            return `${tooltipItems?.dataset?.label}: ${tooltipItems.formattedValue}%`;
          },
        },
      },
      title: {
        display: true,
      },
      legend: {
        position: "bottom",
        fullSize: false,
        display: false,
        labels: {
          font: {
            size: 12,
            weight: 400,
          },
          usePointStyle: true,
          padding: 10,
          color: "#151A30",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    animation: {
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default") {
          delay = context.dataIndex * 20;
        }
        return delay;
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          count: 10,
          color: "#22577A",
          maxRotation: window.innerWidth > 750 ? 0 : 90,
          minRotation: window.innerWidth > 750 ? 0 : 90,
          labelOffset: -10,
          font: {
            size: 12,
            family: "Gilroy",
            weight: 600,
          },
        },
        title: {
          display: true,
          text: (context) => {
            const { innerWidth } = window;
            return innerWidth > 600
              ? "% of Advertising Costs Financed With Revenue Roll"
              : ["% of Advertising Costs Financed With", "Revenue Roll"];
          },
          color: "#57CC99",
          font: {
            size: 16,
            weight: 700,
            lineHeight: 2,
            family: "Gilroy",
          },
          // align: "center",
        },
      },
      y: {
        stacked: true,
        grid: {
          color: "#22577A10",
          display: true,
        },
        ticks: {
          color: "#22577A",
          stepSize: 2000,
          maxRotation: 0,
          maxTicksLimit: 10,
          minRotation: 0,
          font: {
            size: 12,
            family: "Gilroy",
            weight: 600,
            display: function (context) {
              return context.chart.width > 720;
            },
          },
          callback: (value) => {
            const { innerWidth: width } = window;
            if (width >= 750) {
              return value < 0
                ? `(${(value * -1)?.toLocaleString()})%  `
                : `${value?.toLocaleString()}%  `;
            } else {
              if (Math.abs(value) < 1000) {
                return value < 0
                  ? `(${(value * -1)?.toLocaleString()})%  `
                  : `${value?.toLocaleString()}%  `;
              } else {
                return value < 0
                  ? `(${Math.abs(Math.floor(value / 1000))}k)%  `
                  : `${Math.abs(Math.floor(value / 1000))}k%  `;
              }
            }
          },
        },
        title: {
          display: true,
          text: "Return On Investment",
          fullSize: true,
          font: {
            size: 16,
            weight: 700,
            family: "Gilroy",
          },
          color: "#57CC99",
          padding: {
            bottom: 2,
          },
          align: "center",
        },
      },
    },
  };

  const data = (NRoasImpv, roasImpv, financial_method) => {
    let temp = NRoasImpv;
    let test = roasImpv;
    if (test && test[0] === 0) {
      test[0] = undefined;
    }
    if (temp && (temp[0] === "Infinity" || temp[0] === "-Infinity")) {
      temp[0] = undefined;
    }
    if (financial_method === "borrowed") {
      temp = [
        NRoasImpv[0],
        NRoasImpv[5],
        NRoasImpv[6],
        NRoasImpv[7],
        NRoasImpv[8],
        NRoasImpv[9],
      ];
      test = [
        roasImpv[0],
        roasImpv[5],
        roasImpv[6],
        roasImpv[7],
        roasImpv[8],
        roasImpv[9],
      ];
      labels = ["Status Quo", "100%", "125%", "150%", "175%", "200%"];
    } else {
      labels = [
        "Status Quo",
        "0%",
        "25%",
        "50%",
        "75%",
        "100%",
        "125%",
        "150%",
        "175%",
        "200%",
      ];
    }
    return {
      labels,
      datasets: [
        {
          label: "No ROAS Improvement",
          data: temp,
          backgroundColor: isHovered ? "#80ED9940" : "#80ED99",
          hoverBackgroundColor: "#80ED99",
          borderColor: "#80ED99",
          borderWidth: 0,
          barPercentage: 0.6,
          stack: "Stack 0",
          borderRadius: 6,
          datalabels: {
            display: false,
          },
        },
        {
          label: "ROAS Improvement",
          data: test,
          backgroundColor: isHovered ? "#38A3A540" : "#38A3A5",
          hoverBackgroundColor: "#38A3A5",
          borderColor: "#38A3A5",
          borderWidth: 0,
          barPercentage: 0.6,
          stack: "Stack 1",
          borderRadius: 6,
          width: 10,
          datalabels: {
            display: false,
          },
        },
      ],
    };
  };

  const toggleData = (value) => {
    const chart = chartRef.current;
    chart.isDatasetVisible(value) ? chart.hide(value) : chart.show(value);
  };

  const onChartHoverChange = (hovered) => {
    if (isHovered !== hovered) {
      setIsHovered(hovered);
    }
  };

  const redirectToApp = () => {
    window.open("https://www.revenueroll.com/book-demo");
  };

  return (
    <div>
      <div className="flex items-center chart-heading-wrapper">
        <p className="sub-heading">
          Advertise Now Pay Later
        </p>
        <div className="group relative">
          <div className="info-icon">
            <p>!</p>
          </div>

          <div className="hidden group-hover:block">
            <div className="section-info-div">
              <ul>
                <li>
                  Our financing minimizes the down-payment for advertising and
                  maximizes your return on campaign investment
                </li>
                <li>
                  For select clients, we are willing to expand the line of
                  credit beyond your monthly historical ad spend, exponentially
                  increasing your returns on investment
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="box-btn-wrapper group-bar-chart">
        <div className="number-box">
          <div className="box-content">
            <p>You're Eligible For </p>
            <p className="box-value">${financingAmount}</p>
            <p>In Advertising Financing</p>
          </div>
        </div>
        <button className="box-btn" onClick={redirectToApp}>
          Apply Now
        </button>
      </div>
      <div className="group-bar-chart-wrapper">
        <div className="gc-overlays">
          <div
            className={
              "gc-st-quo-overlay" +
              (financial_method === "borrowed" ? " borrowed" : " cash")
            }
          >
            <p>Status Quo</p>
          </div>
          <div
            className={
              "gc-rr-man-overlay" +
              (financial_method === "borrowed" ? " borrowed" : " cash")
            }
          >
            <p>Revenue Roll</p>
          </div>
          <div
            className={
              "gc-be-overlay" +
              (financial_method === "borrowed" ? " borrowed" : " cash")
            }
          >
            <p>Budget Expansion</p>
          </div>
        </div>
        <Bar
          ref={chartRef}
          options={options}
          onMouseEnter={() => onChartHoverChange(true)}
          onMouseLeave={() => onChartHoverChange(false)}
          data={data(NRaosImpv, raosImpv, financial_method)}
        />
      </div>
      <div className="chart-legends">
        {data().datasets.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <div className="legend" onClick={() => toggleData(i)}>
                <div
                  className="color-box"
                  style={{
                    background: d.hoverBackgroundColor,
                  }}
                ></div>{" "}
                <p>{d.label}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default GroupBarChart;
