import { useCallback, useEffect } from "react";
import { useState } from "react";

export const PillSelector = ({ className, options, onChange, defaultValue: selected }) => {
  const [bgPosition, setBgPosition] = useState("0%");

  const changeBgPosition = useCallback(
    (i) => {
      if (i === 0) {
        setBgPosition(`1%`);
      } else {
        setBgPosition(`${(i / options.length) * 100}%`);
      }
    },
    [options]
  );
  const valueChanged = (i, value) => {
    changeBgPosition(i);
    onOptionSelect(value);
  };

  useEffect(() => {
    for (let i = 0; i < options.length; i++) {
      const element = options[i];
      if (selected.value === element.value) {
        changeBgPosition(i);
      }
    }
  }, [selected, options, changeBgPosition]);

  const onOptionSelect = (o) => {
    onChange(o);
  };
  return (
    <div className={"pill-group " + (className ? className : "")}>
      <div
        className="bg"
        style={{
          left: bgPosition,
          transition: ".5s",
          width: `${97 / options.length}%`,
        }}
      ></div>
      {options?.map((o, i) => (
        <div
          key={i}
          className={o.value === selected?.value ? "single-pill selected" : "single-pill"}
          onClick={() => {
            valueChanged(i, o);
          }}
        >
          {o.label}
        </div>
      ))}
    </div>
  );
};
