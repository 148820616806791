import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

const LIMITS = {
  MAX: 10_000_000 * 12,
  MIN: 100 * 12,
};

const RevenueInputAnnual = ({
  temp,
  setTemp,
  handleOnlineRevenue,
  handleOnlineRevenueDataUpdate,
  d_online_revenue_annual,
}) => {
  const [tempAmount, setTempAmount] = useState(d_online_revenue_annual);

  const onBlurEvent = () => {
    let value = tempAmount;
    if (value === undefined) {
      value = LIMITS.MIN;
      setTempAmount(LIMITS.MIN);
    } else if (value < LIMITS.MIN) {
      value = LIMITS.MIN;
      setTempAmount(LIMITS.MIN);
    } else if (value > LIMITS.MAX) {
      value = LIMITS.MAX;
      setTempAmount(LIMITS.MAX);
    }
    setTimeout(() => {
      setTemp(value);
      handleOnlineRevenue(value);
    }, 100);
  };

  useEffect(() => {
    setTempAmount(d_online_revenue_annual);
  }, [d_online_revenue_annual]);

  return (
    <div className={`col-span-4 md:col-span-2  px-2 input-field`}>
      <NumberFormat
        thousandsGroupStyle="thousand"
        prefix={"$ "}
        // suffix={" %"}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        className={`bg-inherit w-full h-full focus:outline-none `}
        onValueChange={(e) => {
          setTempAmount(e.floatValue);
        }}
        value={tempAmount}
        decimalScale={2}
        onBlur={() => {
          if (temp !== undefined) {
            // if (temp !== d_online_revenue_annual) {
            onBlurEvent();
            handleOnlineRevenueDataUpdate("online_revenue");
            // }
          }
        }}
      />
    </div>
  );
};

export default RevenueInputAnnual;
