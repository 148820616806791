import React from "react";

import box1Image from "../../Assets/box1Icon.svg";
import box2Image from "../../Assets/box2Icon.svg";
import box3Image from "../../Assets/box3Icon.svg";

export const Difference = () => {
  return (
    <div className="difference-component">
      <h6 className="sub-heading">How We Are Different</h6>
      <div className="differences">
        {/* Box 1 */}
        <div className="difference-box box-1">
          <div className="difference-icon">
            <img src={box1Image} alt="" />
          </div>

          <div className="difference-text">
            <p>
              We <span>prioritize our clients' success</span> ahead of
              everything else.
            </p>
          </div>
        </div>
        {/* Box 2 */}
        <div className="difference-box box-2">
          <div className="difference-icon">
            <img src={box2Image} alt="" />
          </div>
          <div className="difference-text">
            <p>
              We've built a system that incentivizes us to{" "}
              <span> grow with you.</span>
            </p>
          </div>
        </div>
        {/* Box 3 */}
        <div className="difference-box box-3">
          <div className="difference-icon">
            <img src={box3Image} alt="" />
          </div>
          <div className="difference-text">
            <p>
              Our credit product only{" "}
              <span>accrues fees as it is deployed.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
