import React from "react";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
const InputSectionHeader = ({
  headerName,
  onClick,
  tooltipText,
  tooltipId,
}) => {
  return (
    <div onClick={onClick}>
      <div className="section-heading-wrapper">
        <p className="section-heading-text">{headerName}</p>
        {tooltipText && (
          <>
            <div id={tooltipId} className="section-info-icon">
              <FiInfo />
            </div>
            <Tooltip anchorSelect={`#${tooltipId}`} place="bottom">
              {tooltipText}
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(InputSectionHeader);
