import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { useDispatch, useSelector } from "react-redux";
import { Difference } from "../Difference";
import Infogram from "../Infogram";
import RASBarChart from "../Infogram/ChartJs/RASBarChart";
import { Table } from "../Infogram/Table/Table";
import Input from "../Input";
import { useLocation } from "react-router-dom";
import { setV2Landing, setFullCalc, setParentPage } from "../../redux/slice/uiSlice";

const IndexPage = () => {
  const [prevData, setPrevData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const data = useSelector((state) => state?.data);
  const dispatch = useDispatch();

  const location = useLocation();

  const [showMoreData, setShowMoreData] = useState(false);

  const isMobile = window.innerWidth <= 750;
  const { v2Landing, fullCalc, parentPage } = useSelector((s) => s.ui);

  const getData = useCallback(async () => {
    const ApiCall = async () => {
      const apidata = await axios.post("https://rr-calculator-gateway-9r1eosgv.uc.gateway.dev/v2/calculator", {
        ...data,
        a_client_type: data?.a_client_type?.value,
        b_event_type: data?.b_event_type?.value,
        m_financial_method: data?.m_financial_method?.value,
      });
      return apidata;
    };

    if (
      (data?.a_client_type &&
        data?.c_online_revenue_monthly !== undefined &&
        data?.d_online_revenue_annual !== undefined &&
        data?.e_online_event_monthly !== undefined &&
        data?.f_online_event_annual !== undefined &&
        data?.g_event_worth_monthly !== undefined &&
        data?.h_event_worth_annual !== undefined &&
        data?.i_revenue_earned_monthly !== undefined &&
        data?.j_revenue_earned_annual !== undefined) ||
      (data?.m_financial_method?.value === "borrowed" &&
        data?.n_financial_interest_rate_monthly !== undefined &&
        data?.o_financial_interest_rate_annual !== undefined)
    ) {
      if (JSON.stringify(data) === JSON.stringify(prevData)) {
        console.log("no changes");
      } else {
        const apidataPrev = await ApiCall();
        console.log("getting response", apidataPrev);
        if (apidataPrev.status === 200) {
          setPrevData(data);
          setGraphData(apidataPrev);
        }
      }
    } else {
      console.log("data not send");
    }
  }, [data, prevData]);

  useEffect(() => {
    getData();
  }, [data, getData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const landingCalc = params.get("landing");
    const landingCalcV2 = params.get("landingV2");
    const parentPage = params.get("parentPage");
    if (landingCalc === "true") {
      dispatch(setFullCalc(false));
    } else {
      dispatch(setFullCalc(true));
    }
    setFullCalc(landingCalc !== "true");
    if (landingCalcV2) {
      dispatch(setFullCalc(false));
      dispatch(setV2Landing(true));
    } else {
      dispatch(setV2Landing(false));
    }

    if (parentPage) {
      dispatch(setParentPage(parentPage));
    }
  }, [location.search, dispatch]);

  const tableData = graphData?.data?.return_calculations;

  const roiImproved = tableData?.improved_roas?.unlevered?.return_on_campaign_investment;
  const roiSQuo = tableData?.status_quo?.return_on_campaign_investment;

  let roiAmount = (roiImproved / roiSQuo).toFixed(1);
  if (isNaN(roiAmount)) {
    roiAmount = "-";
  } else if (roiAmount < 0) {
    roiAmount = "10x+";
  } else {
    roiAmount = `${roiAmount}x`;
  }

  const statusQuo = graphData?.data?.improved_ad_performance?.status_quo?.toFixed(1);

  const topStatsData = { roiAmount };

  const RasApiData = graphData?.data?.roasSensitivity?.rr_managed;
  const RasApiCRData = graphData?.data?.roasSensitivity?.status_quo;
  // console.log("rrr", RasApiData);
  const RasGroupBar = {
    labels: [
      isMobile ? ["Status", "Quo"] : "Status Quo",
      RasApiData?.row1?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row2?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row3?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row4?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row5?.untitled_times?.toFixed(2) + "x",
    ],
    as: [
      RasApiCRData?.ad_spend,
      RasApiData?.row1?.ad_spend,
      RasApiData?.row2?.ad_spend,
      RasApiData?.row3?.ad_spend,
      RasApiData?.row4?.ad_spend,
      RasApiData?.row5?.ad_spend,
    ],

    cc: [
      RasApiCRData?.contractor_cost,
      RasApiData?.row1?.contractor_cost,
      RasApiData?.row2?.contractor_cost,
      RasApiData?.row3?.contractor_cost,
      RasApiData?.row4?.contractor_cost,
      RasApiData?.row5?.contractor_cost,
    ],
    fc: [
      RasApiCRData?.financial_costs,
      RasApiData?.row1?.financial_costs,
      RasApiData?.row2?.financial_costs,
      RasApiData?.row3?.financial_costs,
      RasApiData?.row4?.financial_costs,
      RasApiData?.row5?.financial_costs,
    ],
    mr: [
      RasApiCRData?.monthly_revenue,
      RasApiData?.row1?.monthly_revenue,
      RasApiData?.row2?.monthly_revenue,
      RasApiData?.row3?.monthly_revenue,
      RasApiData?.row4?.monthly_revenue,
      RasApiData?.row5?.monthly_revenue,
    ],
  };

  return (
    <>
      {fullCalc && (
        <div className="full-calculator">
          <div className="">
            <h1 className="main-heading">Projected Performance Calculator</h1>
          </div>
          <div className="my-5">
            <div className="grid grid-cols-1 lg:grid-cols-5 md:grid-cols-6 main-app-grid">
              <Input roasStatusQuo={statusQuo} />
              <div className="lg:col-span-3 md:col-span-3">
                <Infogram graphData={graphData} topStatsData={topStatsData} />
              </div>
            </div>
            <NotificationContainer />
            {/* How we are different */}
            <Difference />

            <div className="details-btn-wrapper">
              <button className="details-btn" onClick={() => setShowMoreData((d) => !d)}>
                {showMoreData ? "Close Details" : "View More Details"}
              </button>
            </div>

            {showMoreData && (
              <>
                <div className="table-div">
                  <h6 className="sub-heading">Detailed Scenarios</h6>
                  <div className="table-scroller">
                    <Table tableData={tableData} />
                  </div>
                </div>
                <div>
                  <RASBarChart
                    as={RasGroupBar.as}
                    cc={RasGroupBar.cc}
                    fc={RasGroupBar.fc}
                    mr={RasGroupBar.mr}
                    labels={RasGroupBar.labels}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {!fullCalc && (
        <div className={"my-5 landing-calculator" + (v2Landing ? " v2-landing" : "")}>
          <div className="grid grid-cols-1 lg:grid-cols-5 md:grid-cols-6 main-app-grid">
            <div className="lg:col-span-2 md:col-span-3">
              <Input roasStatusQuo={statusQuo} fullCalc={fullCalc} isECommPage={parentPage === "eCom"} />
            </div>
            <div className={"lg:col-span-3 md:col-span-3"}>
              <Infogram
                graphData={graphData}
                topStatsData={topStatsData}
                fullCalc={fullCalc}
                className={v2Landing ? "v2-landing-charts" : ""}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IndexPage;
