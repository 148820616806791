import React, { useEffect, useState } from "react";
import InputSectionHeader from "../../InputSectionHeader";
import { useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import CapitalBusinessInputMonthly from "../../../InputSections/AdvertisingInput/CapitalBusinessInputMonthly";
import CapitalBusinessInputAnnual from "../../../InputSections/AdvertisingInput/CapitalBusinessInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { useSelector } from "react-redux";

const OnlineAdvertisingSection = ({ className }) => {
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(0);
  const data = useSelector((state) => state?.data);

  const [k_online_ad_cost_monthly, set_k_online_ad_cost_monthly] = useState(10000);
  const [l_online_ad_cost_annual, set_l_online_ad_cost_annual] = useState(k_online_ad_cost_monthly * 12);
  
  // Sending input data to redux
  const handleOnlineAdCostDataUpdate = () => {
    if (
      data?.k_online_ad_cost_monthly !== k_online_ad_cost_monthly ||
      data?.l_online_ad_cost_annual !== l_online_ad_cost_annual
    ) {
      dispatch(
        addInputData({
          k_online_ad_cost_monthly,
          l_online_ad_cost_annual,
        })
      );
    }
  };

  const handleOnlineAdCostMonthly = (value) => {
    set_k_online_ad_cost_monthly(value);
    set_l_online_ad_cost_annual(value * 12);
  };

  const handleOnlineAdCostAnnual = (value) => {
    set_k_online_ad_cost_monthly(value / 12);
    set_l_online_ad_cost_annual(value);
  };

  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorThree: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorThree: false }));
    }
  }, [temp, dispatch]);

  useEffect(() => {
    set_k_online_ad_cost_monthly(data.k_online_ad_cost_monthly);
  }, [data.k_online_ad_cost_monthly]);

  useEffect(() => {
    dispatch(addInputData({ k_online_ad_cost_monthly, l_online_ad_cost_annual }));
  }, [dispatch, k_online_ad_cost_monthly, l_online_ad_cost_annual]);
  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Advertising"
        tooltipText="How much capital did your business deploy on online advertising to earn that revenue?"
        tooltipId="advTooltip"
      />

      <div>
        <div>
          <div className="flex justify-between input-row">
            <div className=" w-full">
              <p className="input-label">Monthly</p>
              <CapitalBusinessInputMonthly
                setTemp={setTemp}
                handleOnlineAdCost={handleOnlineAdCostMonthly}
                handleOnlineAdCostDataUpdate={handleOnlineAdCostDataUpdate}
                k_online_ad_cost_monthly={k_online_ad_cost_monthly}
              />
            </div>

            <div className="w-full">
              <p className="input-label">Annual</p>
              <CapitalBusinessInputAnnual
                setTemp={setTemp}
                handleOnlineAdCost={handleOnlineAdCostAnnual}
                handleOnlineAdCostDataUpdate={handleOnlineAdCostDataUpdate}
                l_online_ad_cost_annual={l_online_ad_cost_annual}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineAdvertisingSection;
