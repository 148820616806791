import { configureStore } from "@reduxjs/toolkit";
import inputReducer from "./slice/inputSlice";
import errorReducer from "./slice/errorSlice";
import uiReducer from "./slice/uiSlice"
export const store = configureStore({
  reducer: {
    data: inputReducer,
    error: errorReducer,
    ui: uiReducer
  },
});
