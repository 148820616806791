import React, { useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import RASInput from "../../../InputSections/RASInput";
import { externalTooltip } from "../../../../common/chartHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RASBarChart = ({ as, cc, fc, mr, labels }) => {
  const [isHovered, setIsHovered] = useState(false);

  const chartRef = useRef();

  const isMobile = window.innerWidth <= 750;

  const toggleData = (value) => {
    const chart = chartRef.current;
    chart.isDatasetVisible(value) ? chart.hide(value) : chart.show(value);
  };

  const options = {
    tooltipTemplate: "<%if (label){%><%=label %>: <%}%><%= value + ' %' %>",
    layout: {
      padding: {
        top: 60,
        left: 0,
        bottom: -20,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltip,
        yAlign: isMobile ? "bottom" : "",
        bodyFont: {
          size: 16,
        },
        bodySpacing: 30,
        padding: 20,
        callbacks: {
          title: ({ 0: { label } }) => `ROAS ${label}`,
          label: function (context) {
            if (context?.raw > 0) {
              return `${context?.dataset?.label}: ${parseInt(
                context?.raw?.toFixed()
              )?.toLocaleString()}`;
            } else if (context.raw < 0) {
              return `${context?.dataset?.label}: (${parseInt(
                (context?.raw * -1)?.toFixed()
              )?.toLocaleString()})`;
            }
          },
        },
      },
      title: {
        display: true,
        position: "bottom",
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    animation: {
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default") {
          delay = context.dataIndex * 300;
        }
        return delay;
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#22577A",
          stepSize: 1,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          font: {
            family: "Gilroy",
            size: 12,
            weight: 700,
          },
          // padding: 10,
        },
        title: {
          display: true,
          text: "Return on Ad Spend (ROAS)",
          color: "#57CC99",
          align: "center",
          font: {
            weight: 700,
            family: "Gilroy",
            size: 16,
          },
          padding: {
            top: 20,
            bottom: 0,
          },
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
          color: (context) =>
            context?.tick?.value === 0 ? "#38A3A5" : "#22577A10",
        },
        ticks: {
          color: "#22577A",
          font: {
            family: "Gilroy",
            size: 12,
            weight: 700,
          },
          callback: (value) => {
            const { innerWidth: width } = window;
            if (width >= 750) {
              return value < 0
                ? `(${(value * -1)?.toLocaleString()})`
                : `${value?.toLocaleString()}`;
            } else {
              if (Math.abs(value) < 1000) {
                return value < 0
                  ? `(${(value * -1)?.toLocaleString()})`
                  : `${value?.toLocaleString()}`;
              } else {
                return value < 0
                  ? `(${Math.abs(Math.floor(value / 1000))}k)`
                  : `${Math.abs(Math.floor(value / 1000))}k`;
              }
            }
          },
        },
      },
    },
  };
  const barThickness = () =>
    window.innerWidth > 820 ? 100 : window.innerWidth > 680 ? 40 : 20;

  const onChartHoverChange = (hovered) => {
    if (isHovered !== hovered) {
      setIsHovered(hovered);
    }
  };

  const data = (as, cc, fc, mr, labels) => {
    return {
      labels,
      datasets: [
        {
          label: "Monthly Revenue",
          data: mr,
          backgroundColor: isHovered ? "#80ED9940" : "#80ED99",
          hoverBackgroundColor: "#80ED99",
          borderColor: "#80ED99",
          borderWidth: 0,
          stack: "Stack 1",
          barThickness: barThickness(),
          borderRadius: 6,
          datalabels: {
            display: false,
          },
        },
        {
          label: "Ad Spend",
          data: as,
          backgroundColor: isHovered ? "#22577A40" : "#22577A",
          hoverBackgroundColor: "#22577A",
          borderColor: "#22577A",
          borderWidth: 0,
          stack: "Stack 1",
          borderRadius: 6,
          barThickness: barThickness(),
          datalabels: {
            display: false,
          },
        },
        {
          label: "Financing Costs",
          data: fc,
          backgroundColor: isHovered ? "#57CC9940" : "#57CC99",
          hoverBackgroundColor: "#57CC99",
          borderColor: "#57CC99",
          borderWidth: 0,
          stack: "Stack 1",
          borderRadius: 6,
          barThickness: barThickness(),
          datalabels: {
            display: false,
          },
        },
        {
          label: "Other Marketing Costs",
          data: cc,
          backgroundColor: isHovered ? "#38A3A540" : "#38A3A5",
          hoverBackgroundColor: "#38A3A5",
          borderColor: "#38A3A5",
          borderWidth: 0,
          stack: "Stack 1",
          barThickness: barThickness(),
          borderRadius: 6,
          datalabels: {
            display: false,
          },
        },
      ],
    };
  };
  return (
    <div className="mt-4 relative ras-bar-chart">
      <div>
        <div>
          <h6 className="section-heading">
            Expand Your Advertising Budget Instantly
          </h6>
          <p className="section-subheading">
            Explore below how Revenue Roll can maximize your sales and returns
            with little up-front cash required.
          </p>
        </div>
        <div className="md:flex items-center mt-2">
          <p className="main-caption">
            What % of your ad spend would you like to finance with RevRoll?
          </p>
          <div className="md:mx-2 flex items-center mt-2 space-x-2 lg:mt-0 lg:space-x-2">
            <RASInput />
            <p className="min-per"> (Min 100%) </p>
          </div>
        </div>
      </div>
      <div>
        <p className="small-note">
          Note: Any value greater than 100% implies increasing your current
          advertising budget.
        </p>
      </div>
      <div id="ras-bar-chart-wrapper" className="chart-wrapper">
        <div className="ras-bar-overlays">
          <div className="ras-st-quo-overlay">
            <p>Status Quo</p>
          </div>
          <div className="ras-rr-man-overlay">
            <p>Revenue Roll</p>
          </div>
        </div>
        <Bar
          onMouseEnter={() => onChartHoverChange(true)}
          onMouseLeave={() => onChartHoverChange(false)}
          options={options}
          ref={chartRef}
          data={data(as, cc, fc, mr, labels)}
        />
      </div>

      <div
        className="chart-legends"
        style={{ paddingLeft: "5%", paddingTop: 10 }}
      >
        {data().datasets.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <div className="legend" onClick={() => toggleData(i)}>
                <div
                  className="color-box"
                  style={{ background: d.hoverBackgroundColor }}
                ></div>{" "}
                <p>{d.label}</p>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default RASBarChart;
