import React, { useEffect, useState } from "react";
import InputSectionHeader from "../../InputSectionHeader";
import { useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import RetainAgencyInputMonthly from "../../../InputSections/MarketingInput/RetainAgencyInputMonthly";
import RetainAgencyInputAnnual from "../../../InputSections/MarketingInput/RetainAgencyInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { useSelector } from "react-redux";

const MarketingAgencySection = ({ className }) => {
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(0);
  const data = useSelector((state) => state.data);

  const [p_marketing_agency_cost_monthly, set_p_marketing_agency_cost_monthly] = useState(4500);
  const [q_marketing_agency_cost_annual, set_q_marketing_agency_cost_annual] = useState(
    p_marketing_agency_cost_monthly * 12
  );

  // Sending input data to redux
  const handleMarketingAgencyDataUpdate = () => {
    if (
      data?.p_marketing_agency_cost_monthly !== p_marketing_agency_cost_monthly ||
      data?.q_marketing_agency_cost_annual !== q_marketing_agency_cost_annual
    )
      dispatch(
        addInputData({
          p_marketing_agency_cost_monthly,
          q_marketing_agency_cost_annual,
        })
      );
  };

  const handleMarketingAgencyMonthly = (value) => {
    set_p_marketing_agency_cost_monthly(value);
    set_q_marketing_agency_cost_annual(value * 12);
  };

  const handleMarketingAgencyAnnual = (value) => {
    set_p_marketing_agency_cost_monthly(value / 12);
    set_q_marketing_agency_cost_annual(value);
  };

  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorFive: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorFive: false }));
    }
  }, [temp, dispatch]);


  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName={"Other Marketing Costs"}
        tooltipId="marketingTooltip"
        tooltipText="If you retain an agency or contractor to run your ads, put their costs here. If you run them yourself, put zero."
      />

      <div className="section-inputs">
        <div className="flex justify-between input-row">
          <div className=" w-full">
            <p className="input-label">Monthly</p>
            <RetainAgencyInputMonthly
              setTemp={setTemp}
              handleMarketingAgency={handleMarketingAgencyMonthly}
              handleMarketingAgencyDataUpdate={handleMarketingAgencyDataUpdate}
              p_marketing_agency_cost_monthly={p_marketing_agency_cost_monthly}
            />
          </div>
          <div className="w-full">
            <p className="input-label">Annual</p>
            <RetainAgencyInputAnnual
              setTemp={setTemp}
              handleMarketingAgency={handleMarketingAgencyAnnual}
              handleMarketingAgencyDataUpdate={handleMarketingAgencyDataUpdate}
              q_marketing_agency_cost_annual={q_marketing_agency_cost_annual}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingAgencySection;
