import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import InputDropdown from "../../InputDropdown";
import InputSectionHeader from "../../InputSectionHeader";
import { PillSelector } from "../../PillSelector";

const first_dropdown_options = [
  { value: "ecommerce", label: "E-Comm/SaaS" },
  { value: "event", label: "Lead-Gen" },
];

const second_dropdown_options = [
  { value: "leads", label: "Leads" },
  { value: "app_installs", label: "App Installs" },
  { value: "in_app_purchases", label: "In-App Purchases" },
  { value: "crowdfunders", label: "Crowdfunders" },
  { value: "other", label: "Other" },
];

const ClientTypeSection = ({ className, param }) => {
  const data = useSelector((state) => state?.data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (param === "event") {
      dispatch(
        addInputData({
          a_client_type: { value: "event", label: "Event-Driven" },
        })
      );
    } else if (param === "ecommerce") {
      dispatch(
        addInputData({
          a_client_type: {
            value: "ecommerce",
            label: "E-Commerce/SaaS Revenue",
          },
        })
      );
    }
  }, [param, dispatch]);

  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader headerName="Client Type" tooltipText="Select the client type" tooltipId="client-tooltip" />

      <div>
        {/* first drop down input */}

        <div>
          <PillSelector
            options={first_dropdown_options}
            onChange={(e) => dispatch(addInputData({ a_client_type: e }))}
            defaultValue={data?.a_client_type}
          />
        </div>

        {/* Second drop down input */}
        {data?.a_client_type?.value === "event" && (
          <div className="event-type-selector">
            <p className="input-label">What type of event are you looking for?</p>
            <InputDropdown
              options={second_dropdown_options}
              onChange={(e) => dispatch(addInputData({ b_event_type: e }))}
              name="b_event_type"
              defaultValue={data?.b_event_type}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientTypeSection;
